import "./Footer.scss";
var pjson = require("../../../package.json");

const Footer = () => {
  return (
    <footer className="footer">
      <div>Zitro showroom - Video Bingo & Slots {pjson.version}</div>
    </footer>
  );
};

export default Footer;
