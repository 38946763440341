import "./HomeButton.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export default function HomeButton() {
  return (
    <Link to="/">
      <div className="home-icon">
        <FontAwesomeIcon icon={faHome as IconProp} />
      </div>
    </Link>
  );
}
