import { useMutation } from '@tanstack/react-query';
import axios, { AxiosResponse } from "axios";

type myMutationParams ={
  credentials: Credentials;
}

type Credentials = {
  username: string;
  password: string;
};

async function fetchLogin({credentials}:myMutationParams, login_url: string) {
  const url = `integration/login`;
  const res = await axios.post<Credentials, AxiosResponse<{ uuid: string }>>(`${login_url}${url}`, credentials); 
  return res.data.uuid;
}

export const useLogin = (login_url: string) => {
  return useMutation(({credentials:Credentials}:myMutationParams) => fetchLogin({credentials:Credentials}, login_url), {});
}
