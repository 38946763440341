import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import api2 from "../api/api2";
import {isDevelopment} from '../utils'
import { DataProvider } from "../interfaces/interfaces";

const path = (isDevelopment()) ? "providersData" : "config/providers.json?nocache=" + (new Date()).getTime();

async function fetchDataProviders(ctx: QueryFunctionContext) {
  const res = await api2.get<DataProvider>(`/${path}`);
  return res.data.data;
}

export function useDataProviders(){
  return useQuery({
    queryKey: ["providersData"],
    queryFn: fetchDataProviders,
    keepPreviousData: false,
  });
}
