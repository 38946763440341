import { create } from "zustand";

type State = {
  search: string;
};

type Actions = {
  setSearch: (search: string) => void;
};

export const useSearchStore = create<State & Actions>((set) => ({
  search: "",
  setSearch: (search: string) =>
    set(() => ({
      search,
    }))
}));
