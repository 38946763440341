import React from "react";
import LogOutButton from "../LogOutButton/LogOutButton";
import CopyToken from "../CopyToken/CopyToken";
import LauncherButton from "../LauncherButton/LauncherButton";
import { isProEnvironment } from "../../utils";
import { useFeatureFlags } from "../../contexts/FeatureFlags";

type Props = {
  token: string;
};

const FloatingButtons: React.FC<Props> = ({ token }): JSX.Element => {
  const features = useFeatureFlags();
  return (
    <>
      {features.withLogin && <LogOutButton />}
      {!isProEnvironment() && features.withLogin && token !== "withguest" && <CopyToken token={token} />}
      {!isProEnvironment() && features.withLauncher && <LauncherButton />}
    </>
  );
};

export default FloatingButtons;
