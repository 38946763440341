import { create } from "zustand";

type Config = {
  [key: string]: boolean;
};

type State = {
  gameType: string;
  iFrameCompatibility: boolean;
  config: Config;
  mode: string;
  gameUrl: string;
};

type Actions = {
  setGameType: (gameType: string) => void;
  setIFrameCompatibility: (iFrameCompatibility: boolean) => void;
  setConfig: (config: Config) => void;
  setMode: (mode: string) => void;
  setGameURL: (gameurl: string) => void;
};

export const useLauncherStore = create<State & Actions>((set) => ({
  gameType: "slot",
  iFrameCompatibility: true,
  config: {
    "iFrameMode": false,
    "iFrameReload": false,
  },
  mode: "desktop",
  gameUrl: '',
  setGameType: (gameType: string) =>
    set(() => ({
      gameType,
    })),
  setIFrameCompatibility: (iFrameCompatibility: boolean) =>
    set(() => ({
      iFrameCompatibility,
    })),
  setConfig: (config) => set((state) => (
    { ...state.config, config}
  )),
  setMode: (mode) => set((state) => ({ ...state, mode })),
  setGameURL: (gameurl) => set((state) => ({ ...state, gameurl })),
}));
