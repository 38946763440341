import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { blue } from "@mui/material/colors";
import { useLauncherStore } from "../../store/launcher";
import { useFeatureFlags } from "../../contexts/FeatureFlags";
import { Game, Params } from "../../interfaces/interfaces";
import { LANGUAGES } from "../../constants";
import React from "react";

type Props = {
  setUrlForBranch: (url: string) => void;
  urlForBranch: string;
  isBranchTest: boolean;
  setBranchTest: (isBranchTest: boolean) => void;
  params: Params;
  setParams: (params: Params) => void;
  slotGamesSorted: Game[];
  videoBingoGamesSorted: Game[];
};

export default function DefaultFields({
  urlForBranch,
  setUrlForBranch,
  isBranchTest,
  setBranchTest,
  params,
  setParams,
  slotGamesSorted,
  videoBingoGamesSorted,
}: Props) {
  const { withBranch } = useFeatureFlags();
  const { gameType, setGameType, config, setConfig, iFrameCompatibility } =
    useLauncherStore((state) => state);

  const handleChange = <P extends keyof Params>(prop: P, value: Params[P]) => {
    setParams({ ...params, [prop]: value });
  };

  const handleGameTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGameType(e.target.value);
  };

  const handleConfigChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfig({
      ...config,
      [e.target.name]: (e.target as HTMLInputElement).checked,
    });
  };

  const handleChangeGame = (
    e: React.SyntheticEvent,
    value: string,
    name: string
  ): void => {
    setParams({ ...params, [name]: value });
  };

  return (
    <>
      {withBranch && (
        <>
          <div>
            <FormLabel>Test por rama
            <Checkbox
              checked={isBranchTest}
              name="branch"
              onChange={(e) => {
                setBranchTest(e.target.checked);
              }}
              sx={{
                color: blue[800],
                "&.Mui-checked": {
                  color: blue[600],
                },
              }}
            ></Checkbox></FormLabel>
          </div>
        </>
      )}
      {isBranchTest && (
        <>
          <FormLabel>URL</FormLabel>
          <TextField
            type="text"
            name="url"
            value={urlForBranch}
            onChange={(e) => {
              setUrlForBranch(e.target.value);
            }}
            spellCheck="false"
            sx={{
              "& fieldset": {
                borderColor: blue[800],
              },
              "&:hover": {
                "&& fieldset": {
                  border: "1px solid white",
                },
              },
            }}
          />
        </>
      )}
      <div>
        <FormLabel className="label-padding">Game Type</FormLabel>
        <Radio
          name="gameType"
          value="slot"
          id="slot"
          checked={gameType === "slot"}
          onChange={handleGameTypeChange}
          sx={{
            color: blue[800],
            "&.Mui-checked": {
              color: blue[600],
            },
          }}
        />
        <span>slot</span>
        {!isBranchTest && (
          <>
            <Radio
              name="gameType"
              value="bingo"
              id="bingo"
              checked={gameType === "bingo"}
              onChange={handleGameTypeChange}
              sx={{
                color: blue[800],
                "&.Mui-checked": {
                  color: blue[600],
                },
              }}
            />
            <span>bingo</span>
          </>
        )}
      </div>
      {isBranchTest && (
        <>
          <FormLabel>Branch</FormLabel>
          <TextField
            type="text"
            name="branch"
            value={params.branch}
            onChange={(e) => {
              handleChange("branch", e.target.value);
            }}
            spellCheck="false"
            sx={{
              "& fieldset": {
                borderColor: blue[800],
              },
              "&:hover": {
                "&& fieldset": {
                  border: "1px solid white",
                },
              },
            }}
          />
        </>
      )}
      <FormLabel>Game</FormLabel>
      <Autocomplete
        value={params.name}
        onChange={(e, value): void => {
          handleChangeGame(e, value || "", "name");
        }}
        disablePortal
        options={
          gameType === "slot"
            ? slotGamesSorted
                .map(({ name, type }) => {
                  if (isBranchTest) {
                    if (type === "cc") {
                      return name;
                    } else {
                      return null;
                    }
                  } else {
                    return name;
                  }
                })
                .filter((games) => games !== null)
            : videoBingoGamesSorted.map(({ name }) => name)
        }
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
            color: "black",
          },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
            {
              color: "black",
            },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
            {
              color: "black",
            },
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      <div>
        <FormLabel>Demo 
        <Checkbox
          checked={Boolean(+params.demo)}
          name="demo"
          onChange={(e) => {
            handleChange("demo", e.target.checked);
          }}
          sx={{
            color: blue[800],
            "&.Mui-checked": {
              color: blue[600],
            },
          }}
        ></Checkbox></FormLabel>
      </div>
      {iFrameCompatibility && (
        <div>
          <FormLabel>Iframe mode
          <Checkbox
            checked={config.iFrameMode}
            name="iFrameMode"
            onChange={handleConfigChange}
            sx={{
              color: blue[800],
              "&.Mui-checked": {
                color: blue[600],
              },
            }}
          ></Checkbox></FormLabel>
        </div>
      )}
      {config.iFrameMode && (
        <div>
          <FormLabel>Reload on change screen
          <Checkbox
            checked={config.iFrameReload}
            name="iFrameReload"
            onChange={handleConfigChange}
            sx={{
              color: blue[800],
              "&.Mui-checked": {
                color: blue[600],
              },
            }}
          ></Checkbox></FormLabel>
        </div>
      )}
      {!config.iFrameMode && iFrameCompatibility && (
        <div>
          <FormLabel className="label-padding">Device</FormLabel>
          <Radio
            name="device"
            value="web"
            id="web"
            checked={params.device === "web"}
            onChange={(e) => {
              handleChange("device", e.target.value);
            }}
            sx={{
              color: blue[800],
              "&.Mui-checked": {
                color: blue[600],
              },
            }}
          />
          <span>web</span>
          <Radio
            name="device"
            value="mobile"
            id="mobile"
            checked={params.device === "mobile"}
            onChange={(e) => {
              handleChange("device", e.target.value);
            }}
            sx={{
              color: blue[800],
              "&.Mui-checked": {
                color: blue[600],
              },
            }}
          />
          <span>mobile</span>
        </div>
      )}
      <div>
        <FormLabel className="label-padding">Hide fullscreen hand</FormLabel>
        <Radio
          name="hideFullScreenHand"
          value="true"
          id="true"
          checked={params.hideFullScreenHand === "true"}
          onChange={(e) => {
            handleChange("hideFullScreenHand", e.target.value);
          }}
          sx={{
            color: blue[800],
            "&.Mui-checked": {
              color: blue[600],
            },
          }}
        />
        <span>true</span>
        <Radio
          name="hideFullScreenHand"
          value="false"
          id="false"
          checked={params.hideFullScreenHand === "false"}
          onChange={(e) => {
            handleChange("hideFullScreenHand", e.target.value);
          }}
          sx={{
            color: blue[800],
            "&.Mui-checked": {
              color: blue[600],
            },
          }}
        />
        <span>false</span>
      </div>
      <FormLabel>Language</FormLabel>
      <Select
        value={params.lang}
        name="lang"
        onChange={(e) => {
          handleChange("lang", e.target.value);
        }}
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
        }}
      >
        {Object.entries(LANGUAGES).map((lang) => (
          <MenuItem key={lang[0]} value={lang[1]}>
            {lang[0]}
          </MenuItem>
        ))}
      </Select>
      <FormLabel>Cashier URL</FormLabel>
      <TextField
        type="text"
        name="cashierURL"
        value={params.cashierURL}
        onChange={(e) => {
          handleChange("cashierURL", e.target.value);
        }}
        spellCheck="false"
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
        }}
      />
      <FormLabel>Home URL</FormLabel>
      <TextField
        type="text"
        name="homeURL"
        value={params.homeURL}
        onChange={(e) => {
          handleChange("homeURL", e.target.value);
        }}
        spellCheck="false"
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
        }}
      />
      {isBranchTest && (
        <>
          <FormLabel>ProviderId</FormLabel>
          <TextField
            type="text"
            name="providerId"
            value={params.providerId}
            onChange={(e) => {
              handleChange("providerId", e.target.value);
            }}
            spellCheck="false"
            sx={{
              "& fieldset": {
                borderColor: blue[800],
              },
              "&:hover": {
                "&& fieldset": {
                  border: "1px solid white",
                },
              },
            }}
          />
        </>
      )}
      {isBranchTest && (
        <>
          <FormLabel>Provider OperatorId</FormLabel>
          <TextField
            type="text"
            name="providerOperatorId"
            value={params.providerOperatorId}
            onChange={(e) => {
              handleChange("providerOperatorId", e.target.value);
            }}
            spellCheck="false"
            sx={{
              "& fieldset": {
                borderColor: blue[800],
              },
              "&:hover": {
                "&& fieldset": {
                  border: "1px solid white",
                },
              },
            }}
          />
        </>
      )}
    </>
  );
}
