import "./Carousel.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useLocation } from "react-router-dom";
import carouselConfig from "../../config/carousel.json";

type Props = {
  token: string | null;
  demo: boolean;
  gameUrl: string;
};

const Carousel = ({ token, demo, gameUrl }: Props) => {
  const location = useLocation();
  const onClick = (gameName: string) => {
    if (gameName) {
      const url = `${gameUrl}?game=${gameName}&device=web&token=${token}&demo=${+demo}`;
      window.open(url);
    }
  };

  return (
    <div className="carousel">
      {location.pathname === "/slots" && (
        <OwlCarousel
          className="owl-theme slider-items owl-carousel"
          {...carouselConfig.configuration}
        >
          {carouselConfig.slots.map((image) => (
            <div key={image.alt}>
              <img
                src={image.src}
                alt={image.alt}
                onClick={() => {
                  onClick(image.gameName);
                }}
              />
            </div>
          ))}
        </OwlCarousel>
      )}
      {location.pathname === "/vb" && (
        <OwlCarousel
          className="owl-theme slider-items owl-carousel"
          {...carouselConfig.configuration}
        >
          {carouselConfig.videoBingo.map((image) => (
            <div key={image.alt}>
              <img
                src={image.src}
                alt={image.alt}
                onClick={() => {
                  onClick(image.gameName);
                }}
              />
            </div>
          ))}
        </OwlCarousel>
      )}
    </div>
  );
};

export default Carousel;
