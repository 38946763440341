import React from "react";
import { useState } from "react";
import qrModalIcon from "../../assets/qr-icon.png";
import Modal from "../Modal/Modal";
import "./QrModal.scss";
import { Game } from "../../interfaces/interfaces";

type Props = {
  game: Game;
  token: string;
  gameUrl: string;
};

export default function QrModal({ game, token, gameUrl }: Props): JSX.Element {
  const [isShowModal, setShowModal] = useState(false);

  const showModal = () => {
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <div className="qr-modal-container">
      <button className="qrlogo" onClick={showModal}>
        <img src={qrModalIcon} className="qrmodalicon" alt="qr modal icon" />
      </button>

      {isShowModal && (
        <Modal
          show={isShowModal}
          onClose={hideModal}
          game={game}
          token={token}
          gameUrl={gameUrl}
        />
      )}
    </div>
  );
}
