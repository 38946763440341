import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import api from "../api/api";
import {isDevelopment} from '../utils'
import { Data } from "../interfaces/interfaces";

const path = (isDevelopment()) ? "data" : "config/games.json?nocache=" + (new Date()).getTime();

async function fetchData(ctx: QueryFunctionContext) {
  const res = await api.get<Data>(`/${path}`);
  return res.data;
}

export function useData(){
  return useQuery({
    queryKey: ["data"],
    queryFn: fetchData,
    keepPreviousData: false,
  });
}
