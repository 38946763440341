import "./App.scss";
import { ReactNode } from "react";
import {
  Route,
  Switch,
  Redirect,
  HashRouter,
} from "react-router-dom";
import Login from "../Login/Login";
import Dashboard from "../../layouts/Dashboard/Dashboard";
import Launcher from "../../pages/Launcher";
import Registration from "../../pages/Registration";
import Tournament from "../../pages/Tournament";
import { useAuthStore } from "../../store/auth";
import { useFeatureFlags } from "../../contexts/FeatureFlags";
import { useData } from "../../hooks/useData";
import Spinner from "../Spinner/Spinner";
import { useSearchStore } from "../../store/search";

function App() {
  const isAuth = useAuthStore((state) => state.isAuth);
  const search = useSearchStore((state) => state.search);

  const { data, isLoading, isError, error } = useData();
  const { withLogin, withLauncher, withRegistrationPage, withTournamentPage } = useFeatureFlags();

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    return <div>Error! {error as ReactNode} </div>;
  }

  return (
    <HashRouter>
      <div className="showroom">
        <div className="showroom-body">
          <div className="showroom-content">
              <Switch>
                <Route exact path="/tournament">
                  { (!isAuth && withLogin) ? <Login loginUrl={data?.config.login_url}/> : isLoading ? <Spinner /> : withTournamentPage ? <Tournament/> : <></>} 
                </Route>
                <Route exact path="/registration">
                  { withRegistrationPage ? <Registration registrationApiUrl={data?.config.login_url} gameUrl={data?.config.game_url} promotedGame={data?.promotedGame}/> : <></>} 
                </Route>
                <Route exact path="/launcher">
                  {(!isAuth && withLogin) ? <Login loginUrl={data?.config.login_url}/> : isLoading ? <Spinner /> : withLauncher ? <Launcher /> : <></>} 
                </Route>
                <Route exact path="/slots">
                  {(!isAuth && withLogin) ? <Login loginUrl={data?.config.login_url}/> : isLoading ? (
                    <Spinner />
                  ) : (
                    <Dashboard
                      games={data?.slots.games.filter((game) =>
                        game.name.toLowerCase().includes(search.toLowerCase())
                      )}
                      filtering={search.length > 0}
                      gameUrl={data?.config.game_url}
                      baseUrl={data?.config.operatorApiUrl}
                      configUrl={data?.config.config_url}
                    />
                  )}
                </Route>
                <Route exact path="/vb">
                  {(!isAuth && withLogin) ? <Login loginUrl={data?.config.login_url}/> : isLoading ? (
                    <Spinner />
                  ) : (
                    <Dashboard
                      games={data?.vb.games.filter((game) =>
                        game.name.toLowerCase().includes(search.toLowerCase())
                      )}
                      filtering={search.length > 0}
                      gameUrl={data?.config.game_url}
                      baseUrl={data?.config.operatorApiUrl}
                      configUrl={data?.config.config_url}
                    />
                  )}
                </Route>
                <Route exact path="/" render={() => <Redirect to="/slots" />} />
              </Switch>
          </div>
        </div>
      </div>
    </HashRouter>
  );
}

export default App;
