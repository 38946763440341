import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import "./CopyToken.scss";

type Props = {
  token: string;
};

export default function CopyToken({ token }: Props) {
  const copyTokenToClipBoard = () => {
    navigator.clipboard.writeText(token).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  return (
    <div className="copy-token-icon" onClick={copyTokenToClipBoard}>
      <FontAwesomeIcon icon={faClipboard as IconProp} />
    </div>
  );
}
