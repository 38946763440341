import React from "react";
import "./Dashboard.scss";
import { useAuthStore } from "../../store/auth";
import Carousel from "../../components/Carousel/Carousel";
import Preview from "../../components/Preview/Preview";
import { Game } from '../../interfaces/interfaces';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import FloatingButtons from "../../components/FloatingButtons/FloatingButtons";
import ConfigGameBar from "../../components/ConfigGameBar/ConfigGameBar";
import { useFeatureFlags } from "../../contexts/FeatureFlags";
import { useJackpots } from "../../hooks/useJackpots";
import { changeVBgameNames } from "../../utils";
import { PROVIDER_OPERATOR_ID, CURRENCY } from "../../constants"

type Props = {
  games: Game[];
  filtering: boolean;
  gameUrl: string;
  baseUrl: string;
  configUrl: string;
};

const Dashboard = ({ games, filtering, gameUrl, baseUrl, configUrl }: Props) => {
  const token = useAuthStore((state) => state.token);
  const demo = token ? token === "withguest" : false;
  const {withConfigGameBar} = useFeatureFlags();
  const {data} = useJackpots({baseUrl, providerOperatorId: PROVIDER_OPERATOR_ID, currency: CURRENCY, gameName: ""});
  const jackpots = data?.filter(jackpot => jackpot.type === 'MEGA' || jackpot.type === 'BASE30');

  return (
    <div className="dashboard">
      <Header searchIsVisible={true} />
      {!filtering && (
        <div className="dashboard-main-carousel">
          <Carousel token={token} demo={demo} gameUrl={gameUrl} />
        </div>
      )}
      <FloatingButtons token={token} />
      {withConfigGameBar && <ConfigGameBar />}
      <div className="dashboard-games">
        <div className="dashboard-games-list">
          {games.map((game) => (
            <Preview
              game={game}
              key={game.name}
              token={token}
              demo={demo}
              gameUrl={gameUrl}
              configUrl={configUrl}
              jackpot= { jackpots?.find(jackpot => {
                if(jackpot.type === 'BASE30') {
                  const jackpotGames = jackpot.games.map(game => changeVBgameNames(game));
                  return jackpotGames.includes(game.name)
                }
                return jackpot.games.includes(game.name)})! }
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default React.memo(Dashboard);
