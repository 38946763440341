import "./Tournament.scss";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { useAuthStore } from "../store/auth";
import FloatingButtons from "../components/FloatingButtons/FloatingButtons";


export default function Tournament() {
  const token = useAuthStore((state) => state.token);

  const URL = `${window.origin}/leaderboard/?providerId=zitro&providerOperatorId=showroom`;
  return (
    <div className="tournament">
      <Header searchIsVisible={false} />
      <div className="tournament-iframe-container">
        <iframe title="Tournament Iframe" src={URL} className="iframe"></iframe>
      </div>
      <FloatingButtons token={token} />
      <Footer />
    </div>
  );
}
