import { useEffect, useRef } from "react";
import "./Modal.scss";
import { QRCodeSVG } from "qrcode.react";
import qrIcon from "../../assets/qr-icon.png";
import qrZitroDigital from "../../assets/qr-zitrodigital.png";
import { Game } from "../../interfaces/interfaces";
import { useCallback } from "react";

type Props = {
  show: boolean;
  onClose: () => void;
  game: Game;
  token: string;
  gameUrl: string;
};

const Modal = ({ show, onClose, game, token, gameUrl }: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const onCloseClicked = () => {
    if (onClose) onClose();
  };

  const closeModalOnEscKeyDown = useCallback(
    (e: KeyboardEvent): void => {
      if (show && e.code === "Escape" && onClose) {
        onClose();
      }
    },
    [show, onClose]
  );

  // close the modal when the user click outside of the content modal
  const closeModalOnClickOut = useCallback(
    ({ target }: MouseEvent) => {
      if (
        show &&
        target &&
        modalRef.current &&
        !modalRef.current?.contains(target as Node) &&
        onClose
      ) {
        onClose();
      }
    },
    [show, onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeModalOnEscKeyDown);
    document.body.addEventListener("mousedown", closeModalOnClickOut);

    return () => {
      document.removeEventListener("keydown", closeModalOnEscKeyDown);
      document.body.removeEventListener("mousedown", closeModalOnClickOut);
    };
  }, [show, closeModalOnEscKeyDown, closeModalOnClickOut]);

  if (show) {
    return (
      <div className="modal-backdrop">
        <div ref={modalRef} className="modal-content">
          <div className="modal-header">
            <div></div>
            <h1>{game.description.toUpperCase()}</h1>
            <button onClick={onCloseClicked} className="modal-button">
              X
            </button>
          </div>
          <div className="modal-body">
            <QRCodeSVG
              value={`${gameUrl}/?game=${
                game.name
              }&device=mobile&token=${token}&demo=1`}
              size={250}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
              includeMargin={false}
              imageSettings={{
                src: `${qrZitroDigital}`,
                x: undefined,
                y: undefined,
                height: 30,
                width: 30,
                excavate: true,
              }}
            />
          </div>
          <div className="modal-footer">
            <img src={qrIcon} alt="Qr scan me" />
            <p>SCAN ME</p>
          </div>
        </div>
      </div>
    );
  } else {
    return (<></>)
  }
};

export default Modal;
