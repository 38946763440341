import {ENV_PRO} from '../constants'

export const isDevelopment = () => {
    return process.env.NODE_ENV === "development";
}

export const isProEnvironment = () => {
    return window.location.origin === ENV_PRO;
}

export const changeVBgameNames = (gameName: string) => {
    switch (gameName) {
        case "beetleBingo":
            return "beetlebingo";
        case "bingo3":
            return "bingo3000";
        case "bingoCup":
            return "bingocup";
        case "showAll":
            return "showall";
        case "bingoTrucks":
            return "bingotrucks";
        case "bingoDreams":
            return "bingodreams";
        case "burguerBingo":
            return "burgerbingo";
        case "loteriaMexicana":
            return "mexican-lottery";
        case "luckyHero":
            return "lucky";
        case "mexicanGold":
            return "mexicangold";
        case "power4Bonus":
            return "power4bonus";
        case "powerMania":
            return "power";
        case "powerForce":
            return "powerforce";
        case "purpleNight":
            return "purplenight";
        case "secretBingo":
            return "secret";
        case "spinBingo":
            return "spinbingo";
        case "tacoMania":
            return "taco";
        case "ultimoBingoEnParis":
            return "paris";
        case "wildRocks":
            return "rocks";
        case "daVinci":
            return "davinc1";
        case "dobleBonus":
            return "doblebonus";
        case "fireBingo":
            return "firebingo";
        case "turbo":
            return "turbo2";
        case "hotDice":
            return "hotdice";
        case "loteriamexicana":
            return "mexican-lottery";
        case "powermania4bonus":
            return "power4bonus";
        case "powermania":
            return "power";
        case "lacalaca":
            return "calaca";
        case "laloteriamexicana":
            return "mexican-lottery";
        case "tacomania":
            return "taco";
        case "doublebonus":
            return "doblebonus";
        case "powerPrize":
            return "powerprize";
        case "revolution":
            return "revolution";
        case "tripleBonus":
            return "tripleBonus";
        case "dragonBingo":
            return "dragonbingo";
        case "goDice":
            return "goDice";
        case "goPower":
            return "goPower";
            case "secretJungle":
            return "secretJungle";
        default:
            return gameName;
    }
}