import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import "./LogOutButton.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useAuthStore } from "../../store/auth";

export default function LogOut() {
  const logout = useAuthStore((state) => state.logout);
  
  const onClickLogout = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    logout();
    window.location.reload();
  };

  return (
    <div className="log-out-icon" onClick={onClickLogout}>
      <FontAwesomeIcon icon={faSignOutAlt as IconProp} />
    </div>
  );
}
