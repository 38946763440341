import React from "react";
import "./Preview.scss";
import newBrand from "../../assets/new.png";
import comingSoonBrand from "../../assets/coming-soon.png";
import QrModal from "../QrModal/QrModal";
import { Game, Jackpot } from "../../interfaces/interfaces";
import { useFeatureFlags } from "../../contexts/FeatureFlags";
import { useGameParamsStore } from "../../store/gameParams";

type Props = {
  game: Game;
  demo: boolean;
  token: string;
  gameUrl: string;
  jackpot: Jackpot;
  configUrl: string;
};

const Preview = ({ game, token, demo, gameUrl, jackpot, configUrl }: Props) => {
  const { withQRCode, withJackpots } = useFeatureFlags();
  const { params } = useGameParamsStore((state) => state);
  const [previewLoaded, setPreviewLoaded] = React.useState<boolean>(false);
  const openGame = (e: React.MouseEvent<HTMLElement>) => {
    const device = params.isMobile ? "mobile" : "web";
    e.preventDefault();
    const url = `${gameUrl}?game=${
      game.name
    }&device=${device}&token=${token}&demo=${+demo}&lang=${params.lang}`;
    window.open(url);
  };

  const loadPreviewImage = () => {
    const img = new Image();
    img.src = game.isAnimated
    ? `${configUrl}/configurations/images/showroom/previews/preview-${game.name}.svg`
    : `${configUrl}/configurations/images/showroom/previews/preview-${game.name}.jpg`;
    
    img.onload = () => {
      setPreviewLoaded(true);
    };
    img.onerror = () => {
      setPreviewLoaded(false);
    };
  };

  loadPreviewImage();

  return (
    <div className="preview">
      {withJackpots && 
        ((jackpot) ? <div className="preview-jackpot">{`€ ${Intl.NumberFormat().format(jackpot?.value/100)}`}</div> : <div></div>)
      }
      {game.isNew && (
        <img src={newBrand} className="preview-isTagged" alt="zitro digital" />
      )}
      {game.isComingSoon && (
        <img
          src={comingSoonBrand}
          className="preview-isTagged"
          alt="zitro digital"
        />
      )}

      { previewLoaded ? (
        <img
          src={
            game.isAnimated ? 
            `${configUrl}/configurations/images/showroom/previews/preview-${game.name}.svg` : 
            `${configUrl}/configurations/images/showroom/previews/preview-${game.name}.jpg`
          }
          className="preview-image"
          alt={game.name}
        />) : (
        <div className="preview-default-image">
          <p>{game.description}</p>
        </div>
      )}

      <div className="preview-selector">
        <div className="title">{game.description}</div>
        <button onClick={openGame}>
          <span className="material-symbols-outlined">play_arrow</span>
        </button>
      </div>
      {withQRCode && (
        <QrModal game={game} token={token} gameUrl={gameUrl} />
      )}
    </div>
  );
};

export default React.memo(Preview);
