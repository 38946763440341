import { useMutation } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

type myMutationParams = {
  userData: UserData;
};

type UserData = {
  nick: string;
  email: string;
  name: string;
  company: string;
};

async function sendRegistration(
  { userData }: myMutationParams,
  registrationApiUrl: string
) {
  const endPoint = `integration/register`;
  const res = await axios.post<UserData, AxiosResponse<{ token: string }>>(
    `${registrationApiUrl}${endPoint}`,
    userData
  );
  return res.data.token;
}

export const useRegistration = (registrationApiUrl: string) => {
  return useMutation(
    ({ userData: UserData }: myMutationParams) =>
      sendRegistration({ userData: UserData }, registrationApiUrl),
    {}
  );
};
