import { useGameParamsStore } from "../../store/gameParams";
import { LANGUAGES, CURRENCIES } from "../../constants";
import { ConfigParams } from "../../interfaces/interfaces";
import "./ConfigGameBar.scss";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useState, useEffect } from "react";

export default function ConfigGameBar() {
  const { params, setParams } = useGameParamsStore((state) => state);
  const [numFields, setNumFields] = useState(1);

  useEffect(() => {
    let numDisplayedInputs = 0;
    let items = document.getElementsByClassName("game-bar-config-select");
    for (let i = 0; i < items.length; i++) {
      if (getComputedStyle(items[i]).display === "block") numDisplayedInputs++;
    }
    setNumFields(numDisplayedInputs);
  }, [numFields]);

  const handleChange = <P extends keyof ConfigParams>(
    prop: P,
    value: ConfigParams[P]
  ) => {
    setParams({ ...params, [prop]: value });
  };

  const handleConfigChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setParams({
      ...params,
      [e.target.name]: (e.target as HTMLInputElement).checked,
    });
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 82,
    height: 44,
    padding: 12,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(4px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(34px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="25" width="25" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve"><style type="text/css">.st0{fill:%23FFFFFF;}</style><path class="st0" d="M11,38c-0.7,0-1.2-0.2-1.7-0.7c-0.5-0.5-0.7-1.1-0.7-1.7V4.5c0-0.7,0.2-1.2,0.7-1.7C9.8,2.2,10.3,2,11,2h18c0.7,0,1.2,0.2,1.7,0.7c0.5,0.5,0.7,1.1,0.7,1.7v31.1c0,0.7-0.2,1.2-0.7,1.7C30.2,37.8,29.7,38,29,38H11z M20,34.9c0.4,0,0.6-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.9s-0.1-0.6-0.3-0.9c-0.2-0.2-0.5-0.3-0.9-0.3s-0.6,0.1-0.9,0.3s-0.3,0.5-0.3,0.9s0.1,0.6,0.3,0.9S19.6,34.9,20,34.9z M11,29.4h18V8.1H11V29.4z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#fff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#004ca4",
      width: 42,
      height: 42,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="25" width="25" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve"><style type="text/css">.st0{fill:%23FFFFFF;}</style><path class="st0" d="M13.2,37v-1.7l4-4H3.9c-0.8,0-1.4-0.3-2-0.9s-0.8-1.2-0.8-2V5.8c0-0.8,0.3-1.4,0.8-2S3.2,3,3.9,3h32.1c0.8,0,1.4,0.3,2,0.8c0.6,0.6,0.8,1.2,0.8,2v22.7c0,0.8-0.3,1.4-0.8,2c-0.6,0.6-1.2,0.9-2,0.9H22.8l4,4V37H13.2z M3.9,24h32.1V5.8H3.9V24z M3.9,24V5.8V24z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#fff",
      borderRadius: 20 / 2,
    },
  }));

  return (
    <div className="game-bar">
      <div className="game-bar-config">
        {numFields > 1 ? (
          <p className="game-bar-config-field1">Game configuration:</p>
        ) : (
          <p className="game-bar-config-field1">Language:</p>
        )}
        <div className="game-bar-config-div">
          <select
            className="game-bar-config-select"
            value={params.lang}
            name="lang"
            onChange={(e) => {
              handleChange("lang", e.target.value);
            }}
          >
            {Object.entries(LANGUAGES).map((lang) => (
              <option key={lang[0]} value={lang[1]}>
                {lang[0]}
              </option>
            ))}
          </select>
          <select
            className="game-bar-config-select currency-field"
            value={params.currency}
            name="currency"
            onChange={(e) => {
              handleChange("currency", e.target.value);
            }}
          >
            {CURRENCIES.map((currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="game-bar-device">
        <p className="game-bar-config-field2">Device:</p>
        <FormGroup>
          <FormControlLabel
            sx={{ marginRight: 0 }}
            control={
              <MaterialUISwitch
                sx={{ m: 1 }}
                name="isMobile"
                checked={params.isMobile}
                onChange={handleConfigChange}
              />
            }
            label=""
          />
        </FormGroup>
      </div>
    </div>
  );
}
