import { createContext, useContext } from "react";
import { Features } from '../interfaces/interfaces';
import Spinner from "../components/Spinner/Spinner";
import { useData } from "../hooks/useData";

type Props = {
  children?: React.ReactNode;
};

export const FeatureFlags = createContext<Features>({} as Features);

export const FeatureFlagsProvider: React.FC<Props> = ({ children }) => {
  const { data, isLoading } = useData();

  return (
    <FeatureFlags.Provider value={data?.features as Features}>
      {isLoading ? <Spinner /> : children}
    </FeatureFlags.Provider>
  );
};

export const useFeatureFlags = () => useContext<Features>(FeatureFlags);
