import { useRef } from "react";
import "./LegalAgeModal.scss";
import { WITH_GUEST } from "../../constants";

type Props = {
  showMLA: boolean;
  onCloseMLA: () => void;
  loginAsGuest: (key: string) => void;
};

const LegalAgeModal = ({ showMLA, onCloseMLA, loginAsGuest }: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const onCloseClicked = () => {
    if (onCloseMLA) onCloseMLA();
  };

  if (showMLA) {
    return (
      <div className="modal-la-backdrop">
        <div ref={modalRef} className="modal-la-content">
          <div className="modal-la-header">
            <h1>
              Please, confirm that you are of legal age to access these games in
              your country.
            </h1>
          </div>
          <div className="modal-la-body">
            <div className="modal-la-btns">
              <button
                onClick={() => loginAsGuest(WITH_GUEST)}
                className="modal-la-button modal-la-button-yes"
              >
                YES
              </button>
              <button
                onClick={onCloseClicked}
                className="modal-la-button modal-la-button-no"
              >
                NO
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default LegalAgeModal;
