import axios from "axios";
import { isDevelopment } from "../utils";
import { DEV_PORT } from "../constants";

const port = isDevelopment()
  ? `:${DEV_PORT}`
  : window.location.port
  ? `:${window.location.port}`
  : "";

const ENVIRONTMENT = `${window.location.protocol}//${window.location.hostname}${port}`;

const configApi = axios.create({
  baseURL: `${ENVIRONTMENT}`,
});

export default configApi;
