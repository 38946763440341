import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type State = {
  token: string;
  isAuth: boolean;
};

type Actions = {
  setToken: (token: string) => void;
  logout: () => void;
};

export const useAuthStore = create(
  persist<State & Actions>(
    (set) => ({
      token: "",
      isAuth: false,
      errors: null,
      setToken: (token: string) =>
        set(() => ({
          token,
          isAuth: !!token,
        })),
      logout: () => set(() => ({ token: "", isAuth: false })),
    }),
    {
      name: "auth",
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);
