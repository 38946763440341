import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { blue } from "@mui/material/colors";
import { Providers, Game } from "../../interfaces/interfaces";
import { ENVIRONMENT } from "../../constants";
import { useDataProviders } from "../../hooks/useDataProviders";
import { useState, useEffect } from "react";
import { Provider, StringIndex } from "../../interfaces/interfaces";
import { isDevelopment } from "../../utils";
import React from "react";
import axios from "axios";
import { LANGUAGES } from "../../constants";
import { EQUIVALENT_KEYS } from "../../constants";
import { useLauncherStore } from "../../store/launcher";
import Checkbox from "@mui/material/Checkbox";

type Props = {
  providerInputData: StringIndex;
  setProviderInputData: (providerInputData: StringIndex) => void;
  operatorUrl: string;
  setOperatorUrl: (operatorUrl: string) => void;
  slotGamesSorted: Game[];
  videoBingoGamesSorted: Game[];
  ishomeURLValid: boolean;
  iscashierURLValid: boolean;
  setIshomeURLValid: (ishomeURLValid: boolean) => void;
  setIscashierURLValid: (iscashierURLValid: boolean) => void;
};

export default function ProviderFields({
  providerInputData,
  setProviderInputData,
  operatorUrl,
  setOperatorUrl,
  slotGamesSorted,
  videoBingoGamesSorted,
  ishomeURLValid,
  iscashierURLValid,
  setIshomeURLValid,
  setIscashierURLValid,
}: Props) {
  const { data } = useDataProviders();
  const [provider, setProvider] = useState<Provider>({
    name: "",
    base_urls: [],
  });

  const [environment, setEnvironment] = useState(ENVIRONMENT[0]);
  const [providersByEnv, setProvidersByEnv] = useState<Providers>({
    environment: "",
    providers: [],
  });

  const [providerParams, setProviderParams] = useState<StringIndex>({});
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { gameType, setGameType } = useLauncherStore((state) => state);

  const urlPatternValidation = (URL: string) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };

  useEffect(() => {
    const route = isDevelopment()
      ? `http://localhost:3003/providerParams`
      : `${window.location.protocol}//${window.location.hostname}/providers/${provider.name}/launcher/config.json`;

    const getData = async () => {
      try {
        const response = await axios.get(route);
        setProviderParams(response.data.params);
        setProviderInputData({
          [`${response.data.params.lang}`]: "es",
          [`${response.data.params.game}`]:
            gameType === "slot"
              ? slotGamesSorted[0].name
              : videoBingoGamesSorted[0].name,
          [`${response.data.params.device}`]: "mobile",
          [`${response.data.params.hideFullScreenHand}`]: "true",
          [`${response.data.params.demo}`]: "true",
          [`${response.data.params.homeURL}`]:
            "https://www.google.com/search?q=abc&num=3",
          [`${response.data.params.cashierURL}`]:
            "https://www.google.com/search?q=abc&num=3",
        });
        setErrorMessage("");
      } catch (error) {
        setErrorMessage(`Not data found for the provider: ${provider.name}`);
        setProviderInputData({});
        setProviderParams({});
      }
    };
    if (data && provider?.name) getData();
  }, [
    data,
    provider,
    setProviderInputData,
    gameType,
    slotGamesSorted,
    videoBingoGamesSorted,
  ]);

  useEffect(() => {
    let filtered;
    if (data) {
      filtered = data.filter((element) => element.environment === environment);
      setProvidersByEnv(filtered[0]);
      setProvider(filtered[0].providers[0]);
      setOperatorUrl(filtered[0].providers[0].base_urls[0]);
      setProviderInputData({});
    }
  }, [data, environment, setProviderInputData, setOperatorUrl]);

  const handleEnvironment = (e: SelectChangeEvent<string>) => {
    setEnvironment(e.target.value);
    setProvider({
      name: "",
      base_urls: [],
    });
    setOperatorUrl("");
  };

  const handleInputsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (EQUIVALENT_KEYS.demo.includes(e.target.name)) {
      setProviderInputData({
        ...providerInputData,
        [e.target.name]: e.target.checked ? "true" : "false",
      });
    } else {
      setProviderInputData({
        ...providerInputData,
        [e.target.name]: (e.target as HTMLInputElement).value,
      });
    }

    if (EQUIVALENT_KEYS.homeURL.includes(e.target.name)) {
      urlPatternValidation(e.target.value) || !e.target.value
        ? setIshomeURLValid(true)
        : setIshomeURLValid(false);
    }

    if (EQUIVALENT_KEYS.cashierURL.includes(e.target.name)) {
      urlPatternValidation(e.target.value) || !e.target.value
        ? setIscashierURLValid(true)
        : setIscashierURLValid(false);
    }
  };

  const handleTypeSelect = (e: SelectChangeEvent<string>) => {
    setProviderInputData({
      ...providerInputData,
      [e.target.name]: (e.target as HTMLInputElement).value,
    });
  };

  const handleGameTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGameType(e.target.value);
  };

  const handleChangeGame = (
    e: React.SyntheticEvent,
    value: string,
    name: string
  ): void => {
    setProviderInputData({
      ...providerInputData,
      [name]: value,
    });
  };

  const handleProvider = (e: React.SyntheticEvent, value: string): void => {
    if (value) {
      const selectedProvider = providersByEnv.providers.filter(
        (element) => element.name === value
      );
      setProvider(selectedProvider[0]);
      setOperatorUrl(selectedProvider[0].base_urls[0]);
    }
  };

  return (
    <>
      <FormLabel>Environment</FormLabel>
      <Select
        value={environment}
        name="env"
        onChange={(e) => handleEnvironment(e)}
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
        }}
      >
        {ENVIRONMENT.map((env) => (
          <MenuItem key={env} value={env}>
            {env}
          </MenuItem>
        ))}
      </Select>
      <FormLabel>Provider</FormLabel>
      <Autocomplete
        value={provider?.name === undefined ? "" : provider?.name}
        onChange={(e, value): void => {
          handleProvider(e, value || "");
        }}
        disablePortal
        options={
          providersByEnv &&
          [...providersByEnv.providers]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((provider) => provider.name)
        }
        sx={{
          "& fieldset": {
            borderColor: blue[800],
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid white",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
            color: "black",
          },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
            {
              color: "black",
            },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
            {
              color: "black",
            },
        }}
        renderInput={(params) => <TextField {...params} />}
      />

      <FormLabel>Operator URL</FormLabel>
      {provider.name && (
        <Select
          value={operatorUrl}
          name="operatorUrl"
          onChange={(e) => {
            setOperatorUrl(e.target.value);
          }}
          sx={{
            "& fieldset": {
              borderColor: blue[800],
            },
            "&:hover": {
              "&& fieldset": {
                border: "1px solid white",
              },
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
          }}
        >
          {provider.base_urls.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      )}

      <div>
        <FormLabel>Game Type</FormLabel>
        <Radio
          name="gameType"
          value="slot"
          id="slot"
          checked={gameType === "slot"}
          onChange={handleGameTypeChange}
          sx={{
            color: blue[800],
            "&.Mui-checked": {
              color: blue[600],
            },
          }}
        />
        <span>slot</span>

        <Radio
          name="gameType"
          value="bingo"
          id="bingo"
          checked={gameType === "bingo"}
          onChange={handleGameTypeChange}
          sx={{
            color: blue[800],
            "&.Mui-checked": {
              color: blue[600],
            },
          }}
        />
        <span>bingo</span>
      </div>

      {!errorMessage &&
        Object.keys(providerParams).length !== 0 &&
        Object.entries(providerParams).map((entrie) => {

          switch (entrie[0]) {
            case "lang":
              return (
                <React.Fragment key={entrie[0]}>
                  <FormLabel>{entrie[0]}</FormLabel>
                  <Select
                    name={entrie[1]}
                    onChange={handleTypeSelect}
                    value={
                      providerInputData?.[`${entrie[1]}`] === undefined
                        ? ""
                        : providerInputData?.[`${entrie[1]}`]
                    }
                    sx={{
                      "& fieldset": {
                        borderColor: blue[800],
                      },
                      "&:hover": {
                        "&& fieldset": {
                          border: "1px solid white",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                  >
                    {Object.entries(LANGUAGES).map((lang) => (
                      <MenuItem key={lang[0]} value={lang[1]}>
                        {lang[0]}
                      </MenuItem>
                    ))}
                  </Select>
                </React.Fragment>
              );
            case "game":
              return (
                <React.Fragment key={entrie[0]}>
                  <FormLabel>{entrie[0]}</FormLabel>
                  <Autocomplete
                    value={
                      providerInputData?.[`${entrie[1]}`] === undefined
                        ? ""
                        : providerInputData?.[`${entrie[1]}`]
                    }
                    onChange={(e, value): void => {
                      handleChangeGame(e, value || "", entrie[1]);
                    }}
                    disablePortal
                    options={
                      gameType === "slot"
                        ? slotGamesSorted.map(({ name }) => name)
                        : videoBingoGamesSorted.map(({ name }) => name)
                    }
                    sx={{
                      "& fieldset": {
                        borderColor: blue[800],
                      },
                      "&:hover": {
                        "&& fieldset": {
                          border: "1px solid white",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                      "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
                        color: "black",
                      },
                      "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
                        {
                          color: "black",
                        },
                      "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
                        {
                          color: "black",
                        },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </React.Fragment>
              );
            case "device":
              return (
                <React.Fragment key={entrie[0]}>
                  {EQUIVALENT_KEYS.device.includes(entrie[1]) ? (
                    <div>
                      <FormLabel>{entrie[0]}</FormLabel>
                      <Radio
                        name={entrie[1]}
                        value="web"
                        id="web"
                        checked={providerInputData?.[`${entrie[1]}`] === "web"}
                        onChange={handleInputsChange}
                        sx={{
                          color: blue[800],
                          "&.Mui-checked": {
                            color: blue[600],
                          },
                        }}
                      />
                      <span>web</span>
                      <Radio
                        name={entrie[1]}
                        value="mobile"
                        id="mobile"
                        checked={
                          providerInputData?.[`${entrie[1]}`] === "mobile"
                        }
                        onChange={handleInputsChange}
                        sx={{
                          color: blue[800],
                          "&.Mui-checked": {
                            color: blue[600],
                          },
                        }}
                      />
                      <span>mobile</span>
                    </div>
                  ) : (
                    <React.Fragment>
                      <FormLabel>{entrie[0]}</FormLabel>
                      <TextField
                        placeholder={`${entrie[0]}`}
                        name={entrie[1]}
                        onChange={handleInputsChange}
                        value={
                          providerInputData?.[`${entrie[1]}`] === undefined
                            ? ""
                            : providerInputData?.[`${entrie[1]}`]
                        }
                        sx={{
                          "& fieldset": {
                            borderColor: blue[800],
                          },
                          "&:hover": {
                            "&& fieldset": {
                              border: "1px solid white",
                            },
                          },
                        }}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              );
            case "hideFullScreenHand":
              return (
                <div key={entrie[0]}>
                  <FormLabel>{entrie[0]}</FormLabel>
                  <Radio
                    name={entrie[1]}
                    value="true"
                    id="true"
                    checked={providerInputData?.[`${entrie[1]}`] === "true"}
                    onChange={handleInputsChange}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blue[600],
                      },
                    }}
                  />
                  <span>true</span>
                  <Radio
                    name={entrie[1]}
                    value="false"
                    id="false"
                    checked={providerInputData?.[`${entrie[1]}`] === "false"}
                    onChange={handleInputsChange}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blue[600],
                      },
                    }}
                  />
                  <span>false</span>
                </div>
              );
            case "demo":
              return (
                <React.Fragment key={entrie[0]}>
                  {EQUIVALENT_KEYS.demo.includes(entrie[1]) ? (
                    <div>
                      <FormLabel>{entrie[0]}
                      <Checkbox
                        checked={Boolean(
                          providerInputData?.[`${entrie[1]}`] === "false"
                            ? 0
                            : 1
                        )}
                        name={entrie[1]}
                        onChange={handleInputsChange}
                        sx={{
                          color: blue[800],
                          "&.Mui-checked": {
                            color: blue[600],
                          },
                        }}
                      ></Checkbox></FormLabel>
                    </div>
                  ) : (
                    <React.Fragment>
                      <FormLabel>{entrie[0]}</FormLabel>
                      <TextField
                        placeholder={`${entrie[0]}`}
                        name={entrie[1]}
                        onChange={handleInputsChange}
                        value={
                          providerInputData?.[`${entrie[1]}`] === undefined
                            ? ""
                            : providerInputData?.[`${entrie[1]}`]
                        }
                        sx={{
                          "& fieldset": {
                            borderColor: blue[800],
                          },
                          "&:hover": {
                            "&& fieldset": {
                              border: "1px solid white",
                            },
                          },
                        }}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              );
            default:
              return (
                <React.Fragment key={entrie[0]}>
                  <FormLabel>{entrie[0]}</FormLabel>
                  <TextField
                    placeholder={`${entrie[0]}`}
                    name={entrie[1]}
                    onChange={handleInputsChange}
                    value={
                      providerInputData?.[`${entrie[1]}`] === undefined
                        ? ""
                        : providerInputData?.[`${entrie[1]}`]
                    }
                    sx={{
                      "& fieldset": {
                        borderColor: blue[800],
                      },
                      "&:hover": {
                        "&& fieldset": {
                          border: "1px solid white",
                        },
                      },
                    }}
                  />
                </React.Fragment>
              );
          }
        })}
      {!ishomeURLValid && (
        <p
          style={{ color: "red", paddingTop: 10 }}
        >{`homeURL is an invalid url`}</p>
      )}
      {!iscashierURLValid && (
        <p
          style={{ color: "red", paddingTop: 10 }}
        >{`cashierURL is invalid url`}</p>
      )}
      {errorMessage && (
        <p style={{ color: "red", paddingTop: 10 }}>{errorMessage}</p>
      )}
    </>
  );
}
