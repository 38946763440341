import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import "./LauncherButton.scss"
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export default function LauncherButton() {
  return (
    <Link to="/launcher">
      <div className="launcher-icon">
        <FontAwesomeIcon icon={faRocket as IconProp} />
      </div>
    </Link>
  );
}