export const ENV_PRO = "https://showroom.zitrogames.com/";
export const DEV_PORT = "3001";
export const DEV_PORT2 = "3002";
export const WITH_GUEST = "withGuest";
export const GAME_DEFAULT_SLOTS = "88KokeshiDolls";
export const GAME_DEFAULT_VB = "catrina";
export const PROVIDER_OPERATOR_ID = "showroom"
export const CURRENCY = "EUR"
export const USERNAME_API_JACkPOTS = "zitro";
export const PASSWORD_API_JACkPOTS = "zitropwd";
export const CURRENCIES = ["EUR", "USD", "MXN", "BRL", "SEK", "COP"];
export const LANGUAGES = {
  Spanish: "es",
  Portuguese: "pt",
  English: "en",
  Russian: "ru",
  Italian: "it",
  Turkish: "tr",
  Bulgarian: "bg"
};
export const ENVIRONMENT = ["uat", "dev", "pro"];
export const EQUIVALENT_KEYS = {
  device: [
    "clienttype",
    "device",
    "mobile",
    "clientType",
    "device_type",
    "clientplatform",
    "mobile",
    "gameid",
  ],
  demo: [
    "mode",
    "demo",
    "freePlay",
    "gameMode",
    "nogsmode",
    "realPlay",
    "isReal",
    "type",
    "real",
    "playmode",
  ],
  cashierURL: [
    "dep_url",
    "cashierURL",
    "cashierUrl",
    "bankingurl",
    "cashierurl",
  ],
  homeURL: [
    "exit_url",
    "homeURL",
    "lobbyURL",
    "LobbyURL",
    "home",
    "lobbyUrl",
    "homeurl",
    "lobbyurl",
    "homeUrl",
    "backurl",
  ],
};
